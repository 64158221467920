<template>
  <span v-if="orderItem">
    <div class="list__item list__item--orderline">
      <div class="list__item__image">
        <img
          :src="orderItem.image ? orderItem.image : `https://images.cyclingfactory.be/${orderItem.externalid}_image_400x.jpg`"
          :alt="orderItem.externalid"
          @error="imgFailed"
          @click="openLightboxImage(orderItem, $event)"
        >
      </div>
      <div class="list__item__data">
        <div class="list__item__data__section list__item__data__section--badges">
          <div class="d-flex align-items-center date-and-labels">
            <div class="d-flex align-items-center labels">
              <!-- Badge preorder -->
              <Badge
                v-if="orderItem.order.preorder"
                type="outlet"
              >{{ $t('account.account.myOrders.preorderLabel') }}</Badge>
              <!-- Badge Shipped -->
              <Badge
                v-if="orderItem.shipped"
                type="shipped"
              >{{ $t('account.account.myOrders.doneStatus.shipped') }}</Badge>
              <a
                v-for="(url, i) in getTrackingLinks()"
                :key="`tracking-${i}`"
                :href="url"
                target="_blank"
                class="tracking-link"
              >{{ $t('account.account.myBackOrders.tracking') }}<i
                class="uil uil-external-link-alt"
              /></a>
              <!--
              <Badge
                type="shipped"
              >{{ `${orderItem.orderline} - ${orderItem.orderline_bike_index}` }}</Badge>-->
            </div>
          </div>
          <span
            v-if="orderItem.serial"
            class="tag tag__serial"
          >
            <i
              class="uil uil-pricetag-alt"
            /> {{ orderItem.serial }}
          </span>
        </div>
        <div class="list__item__data__section list__item__data__section--title">
          <header>
            <!-- Title -->
            <router-link
              v-if="orderItem.itemid"
              :to="{name: 'itemdetail', params: {itemid: orderItem.itemid}}"
              style="font-weight: bold; text-decoration: none;"
              target="_blank"
            >{{ orderItem.description }} <i
              class="uil uil-external-link-alt"
            /></router-link>
            <template v-else><strong>{{ orderItem.description }}</strong></template>
          </header>
        </div>
        <div class="list__item__data__section list__item__data__section--content">
          <div class="list__item__data__section--left">
            <div class="list__item__data__section__info-grid">
              <div class="list__item__data__entry list__item__data__entry--order">
                <span class="label">{{ $t('account.account.myBackOrders.info.order') }}</span>
                <router-link
                  :to="{name: 'myBackOrdersView', params: {id: orderItem.order.externalorderid || orderItem.order.id.toString()}}"
                  style="text-decoration: none;"
                ><span class="value">{{ orderItem.order.externalorderid || "-" }}</span><i
                  class="uil uil-external-link-alt"
                /></router-link>
              </div>
              <div class="list__item__data__entry list__item__data__entry--date">
                <template v-if="orderItem.shipped !== true && orderItem.promiseddeliverydate">
                  <span class="label">{{ $t('account.account.myBackOrders.info.etd') }}</span>
                  <time class="value">
                    {{ formatdate(orderItem.promiseddeliverydate) }}
                  </time>
                </template>
                <template v-else-if="orderItem.shipped !== true && orderItem.requesteddeliverydate">
                  <span class="label">{{ $t('account.account.myBackOrders.info.rtd') }}</span>
                  <time class="value">
                    {{ formatdate(orderItem.requesteddeliverydate) }}
                  </time>
                </template>
                <template v-else-if="orderItem.shipped === true">
                  <span class="label">{{ $t('account.account.myBackOrders.info.shippingdate') }}</span>
                  <time class="value">
                    {{ formatdate(orderItem.shipmentdate) }}
                  </time>
                </template>
              </div>
            </div>
            <div
              v-if="orderItem.order_reference"
              class="list__item__data__entry list__item__data__entry--reference"
            >
              <span class="label">{{ $t('cart.reference') }}</span>
              <span class="value">{{ orderItem.order_reference }}</span>
            </div>
            <div
              v-if="orderItem.reference?.note"
              class="list__item__data__entry list__item__data__entry--cusnote"
            >
              <span class="label">{{ $t('account.account.myBackOrders.info.clientnote') }}</span>
              <expandable-text :text="orderItem.reference.note" />
            </div>
            <div
              v-if="orderItem.nextstatus"
              class="list__item__data__entry list__item__data__entry--status"
            >
              <span class="label">{{ $t('account.account.myBackOrders.info.status') }}</span>
              <span
                v-if="orderItem.change_pending"
                class="value processing disabled"
              >{{ $t('account.account.myBackOrders.info.changePending') }}</span>
              <span
                v-else-if="hasOrderStatusses"
                class="value"
                @click="toggleStatusDetails(orderItem.order.externalorderid || orderItem.order.id.toString())"
              >{{ $t('account.account.myOrders.pendingStatus.' + orderItem.nextstatus) }}<i
                class="uil uil-angle-down"
              />
              </span>
              <span
                v-else
                class="value disabled"
              >{{ $t('account.account.myOrders.pendingStatus.' + orderItem.nextstatus) }}</span>
            </div>
            <div
              v-show="statusDetails.includes(orderItem.order.externalorderid)"
              class="list__item__data__entry--status__details"
            >
              <div
                v-for="(s,i) in orderStatusses"
                :key="i"
                class="list__item__data__entry--status__details__row"
              >
                <div
                  v-if="s.date"
                  class="list__item__data__entry--status__details__content"
                >
                  <span class="status--done">
                    <i class="uil uil-check" />
                  </span>
                  <strong>{{ $t('account.account.myOrders.doneStatus.'+ s.status) }}</strong>
                </div>
                <div
                  v-else
                  class="list__item__data__entry--status__details__content"
                >
                  <span class="status--pending">
                    <i
                      v-if="s.status === orderItem.nextstatus"
                      class="uil uil-clock"
                    />
                  </span>
                  {{
                    s.status === orderItem.nextstatus
                      ? $t('account.account.myOrders.pendingStatus.'+ s.status)
                      : $t('account.account.myOrders.nextStatus.'+ s.status)
                  }}
                </div>
                <div class="list__item__data__entry--status__details__date">
                  <span v-if="s.date">{{ customFormatter(s.date) }}</span>
                  <span v-else>-</span>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!orderItem.change_pending"
            class="list__item__data__section--right"
          >
            <span class="list__item__data__entry"><span class="label">{{ $t('account.account.myBackOrders.info.actions') }}</span></span>
            <div
              class="list__item__data__actions"
              :class="{ 'unreferenced': !orderItem.references}"
            >
              <div
                v-if="canUpdateReference"
                class="list__item__data__entry list__item__data__action list__item__data__action--customer"
                :class="{ 'referenced': orderItem.references}"
                @click="assignItemToCustomer(orderItem)"
              >
                <span class="label">{{ $t('account.account.myBackOrders.info.customer') }}</span>
                <template v-if="orderItem.reference">
                  <div
                    v-if="orderItem.reference"
                    class="value"
                  >{{ orderItem.reference.firstName }} {{ orderItem.reference.lastName }}</div>
                  <div class="list__item__orderitem__actions__customer__edit">{{ $t('account.account.myBackOrders.info.edit') }}<i
                    class="uil uil-pen"
                  />
                  </div>
                </template>
                <span
                  v-else
                  class="edit"
                >{{ $t('account.account.myBackOrders.info.assign') }}<i
                  class="uil uil-user"
                />
                </span>
              </div>
              <div
                v-if="canSwitchSBI"
                class="list__item__data__entry list__item__data__action list__item__data__action--changesbi"
              >
                <span class="label">{{ $t('account.account.myBackOrders.info.changeSBI') }}</span>
                <router-link
                  class="value"
                  :to="{name: 'editPreorder', params: {orderId: orderItem.order.externalorderid || orderItem.order.id.toString(), orderlineId: orderItem.orderline, orderlineBikeIndex: orderItem.orderline_bike_index}}"
                >{{ $t('account.account.myBackOrders.info.change') }}<i
                  class="uil uil-pen"
                /></router-link>
              </div>
              <div
                v-if="canChangeDesign"
                class="list__item__data__entry list__item__data__action list__item__data__action--changedesign"
              >
                <span class="label">{{ $t('account.account.myBackOrders.info.designchange') }}</span>
                <div
                  class="value"
                  @click.stop="openDesignChangePopup()"
                >{{ $t('account.account.myBackOrders.info.change') }}<img
                  src="@/assets/img/configurator-colorwheel.67356ca.png"
                  class="icon"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LightBox
      ref="lightbox"
      :images="lightboxImage"
      :show-caption="false"
      :show-light-box="false"
      :show-thumbs="false"
      background-color="white"
    />
  </span>
</template>

<script>
import { mapState } from 'vuex';
import { formatDate } from '@/utils/dateUtils';
import dialogs from '@/utils/dialogs';
import AssignCustomerDialog from '@/views/account/account/mybackorders/assignCustomerDialog.vue';
import LightBox from '@/components/LightBox/LightBox.vue';
import Badge from '@/elements/Badge.vue';
import { nextOrderStatus } from '@/utils/orderUtils';
import expandableText from '@/components/expandableText.vue';

export default {
  name: 'ListItemOrderline',
  components: {
    LightBox,
    Badge,
    expandableText,
  },
  inheritAttrs: true,
  props: {
    selected: Boolean,
    orderItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lightboxImage: [],
      statusDetails: [],
    };
  },
  computed: {
    ...mapState({
      dhlUrl: (state) => state.language.currentDhl,
      jclUrl: (state) => state.language.currentJcl,
    }),
    canUpdateReference() {
      return true;
    },
    canSwitchSBI() {
      return false;
      // TODO: Check flag from NAV on orderline
      // return this.orderItem.order.preorder;
    },
    canChangeDesign() {
      return false;
      /*
      if (this.orderItem.externalorderid === 'SOB2409277') {
        console.log('customizerAvailable', this.orderItem.customizerAvailable);
        console.log('canswitchdesign', this.orderItem.canswitchdesign);
        console.log('newconfigurationid', this.orderItem.newconfigurationid);
        console.log('designchange', this.orderItem.designchange);
        console.log('designdenied', this.orderItem.designdenied);
      }
      return this.orderItem.customizerAvailable && this.orderItem.canswitchdesign && (!(this.orderItem.newconfigurationid && !this.orderItem.designchange) || (this.orderItem.newconfigurationid && this.orderItem.designdenied));
      */
    },
    hasOrderStatusses() {
      return this.orderStatusses.length > 0;
    },
    orderStatusses() {
      if (!this.orderItem?.orderstatus) return [];
      return [...this.orderItem.orderstatus].sort((a, b) => a.order - b.order);
    },
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    getTrackingLinks() {
      if (!this.orderItem.shipped || !this.orderItem.orderstatus) return [];
      let trackingCodes = [];
      const shipmentStatus = this.orderItem.orderstatus.find((o) => o.status === 'shipped');
      if (shipmentStatus && shipmentStatus.trackingcodes) {
        trackingCodes = [...shipmentStatus.trackingcodes];
      }
      const trackingUrls = trackingCodes.map((code) => {
        const trackingParts = code.split(':').reverse()[0].split('/');
        if (trackingParts.length === 2) {
          return this.jclUrl + trackingParts.reverse()[0];
        }
        return this.dhlUrl + trackingParts.reverse()[0];
      });
      return trackingUrls;
    },
    nextOrderStatus,
    imgFailed(event) {
      event.target.failed = true; // this prevents the lightbox from opening when clicked, see openLightboxImage method.
      // eslint-disable-next-line global-require
      event.target.src = require('@/assets/img/product_placeholder.jpg');
    },
    toggleStatusDetails(externalId) {
      if (this.statusDetails.includes(externalId)) {
        this.statusDetails.splice(this.statusDetails.indexOf(externalId), 1);
      } else {
        this.statusDetails.push(externalId);
      }
    },
    formatdate(dte) {
      return formatDate(dte);
    },
    async assignItemToCustomer() {
      const { order, orderline_bike_index: itemIndex } = this.orderItem;
      const result = await dialogs.show({
        component: AssignCustomerDialog,
        props: {
          order,
          orderline: this.orderItem,
          itemIndex,
          references: this.orderItem.references,
        },
      });
      if (result) {
        this.$emit('updated');
      }
    },
    openLightboxImage(item, event) {
      if (!event.target.failed) {
        if (event.preventDefault) event.preventDefault();
        if (event.stopPropagation) event.stopPropagation();

        const image = {
          thumb: item.image ? item.image : `https://images.cyclingfactory.be/${item.externalid}_image_400x.jpg`,
          src: item.image ? item.image : `https://images.cyclingfactory.be/${item.externalid}_image_2048x.jpg`,
          caption: item.description,
        };
        this.lightboxImage = [{
          ...image,
          key: 'product',
        }];
        this.$refs.lightbox.showImage(0);
      }
    },
    customFormatter(date) {
      return (date) ? formatDate(date) : '-';
    },
    openDesignChangePopup() {

    },
  },
};
</script>

<style scoped>

  .top-section {
    min-height: 3rem;
  }

  .date-and-labels small {
    font-size: 1.7rem;
    margin-top: 0;
    margin-right: 1rem;
  }

  .tag__serial{
    width: auto;
    max-width: 80rem;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height:1.5rem;
    font-size:1.4rem;
    font-family: monospace;
    font-weight: 900;
    letter-spacing: 0.1rem;
    background: black;
    padding: 0.6rem 0.55rem 0.3rem 0.3rem;

    margin: 0;

    color: #CCC;
  }

  .tag__serial i {
    margin-top: 0rem;
    font-size: 1.8rem;
  }
</style>
