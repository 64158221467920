<template>
  <div>
    <!-- Empty msg -->
    <EmptyState
      v-if="itemsResult.length === 0"
      icon="box"
    >
      {{ $t('account.account.myOrders.noOrders') }}
    </EmptyState>
    <list-item-orderline
      v-for="(item) in itemsToDisplay"
      :key="item.id"
      :order-item="item"
      @updated="updated"
    />
    <!-- Pagination -->
    <pagination
      class="m-t-4 m-b-4"
      :page="page"
      :pages="pages"
      :items-per-page="itemsPerPage"
      @pageChanged="changePage"
      @itemsPerPageChanged="itemsPerPageChanged"
    />
  </div>
</template>
<script>
import pagination from '@/components/Pagination.vue';
import EmptyState from '@/elements/EmptyState.vue';
import ListItemOrderline from '@/elements/ListItemOrderline.vue';
import { debounce } from '@/utils/functionUtils';

export default {
  name: 'Orderlinelist',
  components: {
    EmptyState,
    pagination,
    ListItemOrderline,
  },
  props: {
    orderItems: {
      type: Array,
      required: true,
    },
    orders: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    searchVal: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      itemsResult: [],
      itemsPerPage: 20,
      page: 1,
    };
  },
  computed: {
    itemsToDisplay() {
      return this.itemsResult.slice(
        this.page * this.itemsPerPage - this.itemsPerPage,
        this.page * this.itemsPerPage,
      );
    },
    pages() {
      const pages = [];
      for (
        let i = 0;
        i < this.itemsResult.length / this.itemsPerPage;
        i += 1
      ) {
        pages.push(i + 1);
      }
      return pages;
    },
  },
  watch: {
    filters: {
      handler() {
        this.search();
      },
      deep: true,
    },
    searchVal: {
      handler() {
        this.searchDebounced();
      },
    },
    orderItems: {
      handler() {
        this.search(true);
      },
      deep: true,
    },
  },
  created() {
    this.searchDebounced = debounce(this.search, 500);
  },
  mounted() {
    this.search();
  },
  methods: {
    changePage(page) {
      this.page = parseInt(page, 10);
      this.itemsResult.slice(
        this.page * this.itemsPerPage - this.itemsPerPage,
        this.page * this.itemsPerPage,
      );
    },
    itemsPerPageChanged(itemsPerPage) {
      this.itemsPerPage = parseInt(itemsPerPage, 10);
      this.changePage(1);
    },
    updated() {
      this.$emit('updated');
    },
    async search(keepPage = false) {
      if (!keepPage) {
        this.page = 1;
      }
      const filters = {};
      this.filters.filters.forEach((f) => {
        if (!filters[f.type]) {
          filters[f.type] = [];
        }
        filters[f.type].push(f.value);
      });
      if (this.filters.deliveryAddress) {
        filters.deliveryAddress = this.filters.deliveryAddress;
      }

      let searchResults = this.orderItems;
      // First we filter out all items before searching
      // Filters
      searchResults = searchResults.filter((item) => {
        const order = this.orders.find((o) => o.id === item.order.id);
        // Sorting
        if (filters.preorder) {
          if (!filters.preorder.includes(order.preorder)) {
            return false;
          }
        }
        if (item.category && filters.bikesonly && filters.bikesonly[0]) {
          if (!['bikes', 'ffs'].includes(item.category.toLowerCase())) {
            return false;
          }
        }
        if (filters.isSent) {
          const isSent = item.shipped;
          if (!filters.isSent.includes(isSent)) {
            return false;
          }
        }
        if (filters.status) {
          const status = item.nextstatus;
          if (!filters.status.includes(status)) {
            return false;
          }
        }
        if (filters.deliveryAddress) {
          const address = order.deliveryaddressid;
          if (filters.deliveryAddress !== address) {
            return false;
          }
        }
        return true;
      });

      if ((filters.assignable || filters.assigned) && !(filters.assignable && filters.assigned)) {
        if (filters.assignable) {
          searchResults = searchResults.filter((item) => !item.reference);
        } else if (filters.assigned) {
          searchResults = searchResults.filter((item) => !!item.reference);
        }
      }

      // In case of sorting on upcoming items, we also filter all shipped items with a shipping date of more than 2 weeks ago.
      if (this.filters.sort === 'upcoming') {
        searchResults = searchResults.filter((item) => {
          // use reference date of 14days in the passed for shipped items.
          const refDate = new Date();
          refDate.setDate(refDate.getDate() - 14);
          return !item.shipped || item.shipmentdate > refDate.toISOString();
          // item.shipped && item.); // && item.shipmentdate;
        });
      }

      // Do a search on the remaining items if there is a search query.
      // Search
      if (this.searchVal) {
        searchResults = await this.$search(this.searchVal, searchResults, {
          keys: ['order_externalid', 'order_reference', 'reference_searchstr', 'promiseddeliverydate', 'description', 'serials'],
          defaultAll: true,
          caseSensitive: false,
          shouldSort: true,
          tokenize: false,
          matchAllTokens: true,
          findAllMatches: true,
          threshold: 0.2,
          location: 2,
          distance: 50,
          maxPatternLength: 32,
          minMatchCharLength: 1,
        });
      }

      // Do sorting now
      if (this.filters.sort === 'ETD') {
        searchResults = searchResults.sort((a, b) => { // Not perfect, but we will sort on the ETD of the earliest available non-shipped item in case of ETD
          // Show unshipped ones first
          if ((a.shipped) > (b.shipped)) {
            return 1;
          } if ((a.shipped) < (b.shipped)) {
            return -1;
          }

          // Order the shipped ones on orderdate DESC
          if ((a.shipped)) { // In case we already shipped, then we will sort on orderdate DESCENDING
            if (a.order.orderdate > b.order.orderdate) {
              return -1;
            } if (a.order.orderdate < b.order.orderdate) {
              return 1;
            }
            return 0;
          }

          // Order the unshipped items according to ETD
          if (a.promiseddeliverydate < b.promiseddeliverydate) {
            return -1;
          } if (a.promiseddeliverydate > b.promiseddeliverydate) {
            return 1;
          }
          return 0;
        });
      } else if (this.filters.sort === 'shipmentdate') {
        searchResults = searchResults.sort((a, b) => { // Not perfect, but we will sort on the ETD of the earliest available non-shipped item in case of ETD
          // Show shipped ones first
          if ((a.shipped) > (b.shipped)) {
            return -1;
          } if ((a.shipped) < (b.shipped)) {
            return 1;
          }

          // Order the shipped ones on shipment date DESC
          if ((a.shipped)) {
            if (a.shipmentdate > b.shipmentdate) {
              return -1;
            } if (a.shipmentdate < b.shipmentdate) {
              return 1;
            }
            return 0;
          }

          // In case we haven't shipped yet, we will sort on ETD Ascending (soonest arrivals show up first)
          if (a.order.rderdate > b.order.orderdate) {
            return -1;
          } if (a.order.orderdate < b.order.orderdate) {
            return 1;
          }
          return 0;
        });
      } else if (this.filters.sort === 'upcoming') {
        searchResults = searchResults.sort((a, b) => {
          const dateA = a.shipmentdate || a.promiseddeliverydate || a.requesteddeliverydate;
          const dateB = b.shipmentdate || b.promiseddeliverydate || b.requesteddeliverydate;

          if (dateA && dateB) {
            return new Date(dateA) - new Date(dateB);
          }
          if (dateA) return -1;
          if (dateB) return 1;
          return 0;
        });
      }

      this.itemsResult = searchResults;
    },
  },
};
</script>
<style>
</style>
